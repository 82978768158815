/* eslint-disable react-hooks/rules-of-hooks */
import React, { Component, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { BiCategory } from 'react-icons/bi';
import { GiResize, GiBoxUnpacking } from 'react-icons/gi';
import { MdGeneratingTokens } from 'react-icons/md';
import { GrProductHunt, GrCatalog, GrInspect } from 'react-icons/gr';
import { FaSlidersH, FaServer } from 'react-icons/fa';
import { BsNewspaper, BsPersonLinesFill, BsBuilding, BsGlobe, BsFillChatSquareDotsFill, BsBank } from 'react-icons/bs';
import { AiTwotoneSetting, AiFillDashboard } from 'react-icons/ai';
import { GoFileSubmodule } from 'react-icons/go';
import { CgProfile } from 'react-icons/cg';
import { SiMicrosoftteams, SiAudiotechnica } from 'react-icons/si';

const Sidebar = () => {
    const [loginId, setloginId] = useState('')

    const getLoginId = () => {
        let lid = sessionStorage.getItem('login_id');
        setloginId(lid)
    }

    useEffect(() => {
        getLoginId();
    }, [])

    return (
        <>
            <div className="web-category">
                <nav className="sidebar sidebar-offcanvas" id="sidebar">
                <ul className="nav">
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/dashboard">
                                <AiFillDashboard size={20}></AiFillDashboard>
                                <span className="menu-title">Dashboard</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/category">
                                <BiCategory size={20}></BiCategory>
                                <span className="menu-title">Category</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/sub-category">
                                <BiCategory size={20}></BiCategory>
                                <span className="menu-title">Sub Category</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/size">
                                <GiResize size={20}></GiResize>
                                <span className="menu-title">Size</span>
                            </NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/series">
                                <FaServer size={20}></FaServer>
                                <span className="menu-title">Series</span>
                            </NavLink>
                        </li> */}
                        <li className="nav-item ">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/product">
                                <GrProductHunt size={20}></GrProductHunt>
                                <span className="menu-title">Product</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/inquiry">
                                <CgProfile size={20}></CgProfile>
                                <span className="menu-title">Inquiry</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/slider">
                                <FaSlidersH size={20}></FaSlidersH>
                                <span className="menu-title">Slider</span>
                            </NavLink>
                        </li>
                        {/* <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/home-slider">
                                <FaSlidersH size={20}></FaSlidersH>
                                <span className="menu-title">Home Product Slider</span>
                            </NavLink>
                        </li> */}
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/catalogue">
                                <GrCatalog size={20}></GrCatalog>
                                <span className="menu-title">Catalogue</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/packing">
                                <GiBoxUnpacking size={20}></GiBoxUnpacking>
                                <span className="menu-title">Technical Specification</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/product-feature">
                                <GrInspect size={20}></GrInspect>
                                <span className="menu-title">Product Features</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/news">
                                <BsNewspaper size={20}></BsNewspaper>
                                <span className="menu-title">News</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/testimonial">
                                <MdGeneratingTokens size={20}></MdGeneratingTokens>
                                <span className="menu-title">Testimonial</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/teams">
                                <SiMicrosoftteams size={20}></SiMicrosoftteams>
                                <span className="menu-title">Teams</span>
                            </NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink className={({ isActive }) => (isActive ? 'is-active nav-link' : 'nav-link')} to="/admin/setting">
                                <AiTwotoneSetting size={20}></AiTwotoneSetting>
                                <span className="menu-title">Setting</span>
                            </NavLink>
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    )
}
export default Sidebar;