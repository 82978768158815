import React, { useState, useEffect } from 'react';
import { Alert } from 'react-bootstrap';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import LoaderComponent from '../../admin/shared/loader';
import { PAGE_ROUTE, API_URL } from '../shared/constant';



const HomeSliderFormComponent = () => {

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    let { id } = useParams();
    const [error, setError] = useState([]);
    const [formData, setFormData] = useState(
        {
            title: '',
            sub_title: '',
            image: '',
            image_url: '',
            link: ''
        });

    useEffect(() => {
        id && onEdit();
    }, [id])


    const onSubmit = (e) => {
        e.preventDefault();
        if (!id) {

            if (!id && isInvalid(formData.image)) {
                toast.error("Image Is Required");
                return false;
            }
        }
        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_HOME_SLIDER_SAVE;
        let body = formData;
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_HOME_SLIDER_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Slider Updated Successfully', { autoClose: 3000 });
                } else {
                    toast.success('Slider Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_HOME_SLIDER_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            setLoading(false);
            toast.error('Something went wrong..', { autoClose: 3000 })
        });
    }

    const isInvalid = (value) => {
        return (!value || value == '' || value == null);
    };

    const onEdit = () => {
        axios.post(API_URL.ADMIN_HOME_SLIDER_GET + id).then((response) => {
            if (response.data.status === 1) {
                setFormData({
                    title: response.data.data.title,
                    sub_title: response.data.data.sub_title,
                    image_url: response.data.data.image_url,
                    link: response.data.data.link
                });
            }
        });
    }

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>{id ? 'Edit' : 'Add'} Home Slider</h4>
                    {error.length > 0 &&
                        <>
                            {[
                                'danger',
                            ].map((variant) => (
                                <Alert key={variant} variant={variant}>
                                    {error.map((element, key) => {
                                        return (
                                            <div key={key}>{element}</div>
                                        )
                                    })}
                                </Alert>
                            ))}
                        </>
                    }
                    <form className="forms-sample" onSubmit={onSubmit} >
                        <div className='row' >
                            <div className='col-md-6'>
                                <div className='form-group'>
                                    <label>Image</label>
                                    {/* <span className='start'>*</span> */}
                                    <input
                                        className='form-control'
                                        accept='image/*'
                                        name='image'
                                        type="file"
                                        onChange={(e) => {
                                            if (e.target.files && e.target.files[0]) {
                                                var reader = new FileReader();
                                                reader.readAsDataURL(e.target.files[0]);
                                                reader.onload = function () {
                                                    setFormData({ ...formData, image: reader.result });
                                                };
                                                reader.onerror = function (error) {
                                                    console.log('Error: ', error);
                                                };
                                            }
                                        }}></input>
                                </div>
                            </div>
                            <div className='col-md-6'>
                                <label>&nbsp;</label>
                                <div className=''>
                                    {
                                        isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                                    }
                                    <button type="submit" className="btn btn-primary me-2 mt-1" onSubmit={onSubmit}>{id ? 'Update' : 'Create'}</button>
                                    <NavLink to={PAGE_ROUTE.ADMIN_HOME_SLIDER_LIST} className="btn btn-secondary">
                                        Cancel
                                    </NavLink>
                                </div>
                            </div>
                            {id &&
                                <div className='col-md-12 mb-2'>
                                    <a href={formData.image_url} target="_blank" rel="noopener noreferrer">
                                        <img src={formData.image_url} style={{ width: '100%', maxWidth: 500 }} alt="slider_image" />
                                    </a>
                                </div>
                            }
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

}
export default HomeSliderFormComponent;