import React, { useState, useEffect, useRef } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Form } from 'react-bootstrap';
import { PAGE_ROUTE, API_URL } from '../shared/constant';
import { Alert } from 'react-bootstrap';
import { toast } from 'react-toastify';
import LoaderComponent from '../../admin/shared/loader';
import axios from 'axios';
import Crop from '../shared/crop';
import RichTextEditor from 'react-rte';
import Select from 'react-select';

const ProductFormComponent = () => {

    const navigate = useNavigate();
    const [imagePreview, setImagePreview] = useState('');
    let { id } = useParams();
    const [isLoading, setLoading] = useState(false);
    const [loginId, setloginId] = useState('')
    const inputRef = useRef(null);
    const [categoryDrp, setCategoryDrpData] = useState([]);
    const [croptype, setCropType] = useState('round');
    const [subCategoryDrp, setSubCategoryDrp] = useState([]);
    const [error, setError] = useState([]);
    const [seriesDrp, setseriesDrpData] = useState([]);
    const [selectedOption, setSelectedOption] = useState(null);
    const [sizeDrp, setsizeDrpData] = useState([]);
    const [formSpecData, setFormSpecData] = useState([]);
    const [formData, setFormData] = useState({
        name: '',
        category_id: '',
        sub_category_id: '',
        description: RichTextEditor.createEmptyValue(),
        hsn_code: '',
        weight: '',
        unit: '',
        image: '',
        size_id: '',
        series_id: '',
        image_url: '',
        accesories: '',
        app_ids: [],
        specs: [],
        manufacturer: '',
    });

    useEffect(() => {
        if (id) {
            onEdit();
        } else {
            getCategoryDrpData();
        }
    }, [id])

    const getCategoryDrpData = () => {
        axios.post(API_URL.ADMIN_CATEGORY_LIST, {}).then(res => {
            setCategoryDrpData(res.data.data)
        })
    };

    const getSubCategoryDrpData = (value) => {
        axios.post(API_URL.ADMIN_SUB_CATEGORY_BY_CATEGORY, { category_id: value }).then(res => {
            setSubCategoryDrp(res.data.data)
        })
    };

    const getspecsDrp = (value, specs) => {
        axios.post(API_URL.ADMIN_SPEC_LIST, { category_id: value }).then(res => {
            if (res.data) {
                let temp = res.data.map((row) => {
                    let obj = {
                        specs_id: row.id,
                        name: row.name,
                        specs_value: ''
                    }
                    if (specs.length) {
                        const specItem = specs.find(item => item.specs_id === row.id);
                        if (specItem) {
                            obj.specs_value = specItem.specs_value;
                        }
                    }

                    return obj
                });
                setFormSpecData(temp);
            }
        })
    };

    const getseriesDrpData = (category_id = null, sub_category_id = null) => {
        let URL = API_URL.ADMIN_SERIES_LIST;
        if (category_id) {
            URL = URL + '?category_id=' + category_id;
        }
        if (sub_category_id) {
            URL = API_URL.ADMIN_SERIES_LIST;
            URL = URL + '?sub_category_id=' + sub_category_id;
        }
        axios.post(URL, {}).then(res => {
            setseriesDrpData(res.data.data)
        })
    };

    const getsizeDrpData = (category_id = null, sub_category_id = null) => {
        let URL = API_URL.ADMIN_SIZE_LIST;
        if (category_id) {
            URL = URL + '?category_id=' + category_id;
        }
        if (sub_category_id) {
            URL = API_URL.ADMIN_SIZE_LIST;
            URL = URL + '?sub_category_id=' + sub_category_id;
        }
        axios.post(URL, {}).then(res => {
            setsizeDrpData(res.data.data)
        })
    };

    const onSubmit = (e) => {
        e.preventDefault();
        if (!id) {
            if (isInvalid(formData.name)) {
                toast.error("Name Is Required");
                return false;
            }
            if (isInvalid(formData.category_id)) {
                toast.error("Category Is Required");
                return false;
            }
            if (isInvalid(formData.sub_category_id)) {
                toast.error("Sub Category Is Required");
                return false;
            }
            if (isInvalid(formData.size_id)) {
                toast.error("Size Is Required");
                return false;
            }
            if (isInvalid(formData.image)) {
                toast.error("Image Is Required");
                return false;
            }

            if (isInvalid(formData.description)) {
                toast.error("Description Is Required");
                return false;
            }
        }

        setError([]);
        setLoading(true);
        let url = API_URL.ADMIN_PRODUCT_SAVE;
        let body = JSON.parse(JSON.stringify(formData));
        body.description = (formData.description) ? formData.description.toString('html'): "";

        if (body.app_ids && body.app_ids.length > 0) {
            let app_ids = [];
            body.app_ids.map((res) => {
                app_ids.push(res.value);
            });
            body.app_ids = app_ids.toString();
        }

        if (formSpecData && formSpecData.length) {
            body['specs'] = formSpecData;
        }
        if (id) {
            body['id'] = id;
            url = API_URL.ADMIN_PRODUCT_UPDATE;
        }
        axios.post(url, body).then((response) => {
            setLoading(false);
            if (response.data.status === 1) {
                if (id) {
                    toast.success('Product Updated Successfully', { autoClose: 3000 });
                }

                else {
                    toast.success('Product Created Successfully', { autoClose: 3000 });
                }
                setTimeout(() => {
                    navigate(PAGE_ROUTE.ADMIN_PRODUCT_LIST, { replace: true });
                }, 100);
            } else {
                if (response.data && response.data.data) {
                    let errors = [];
                    Object.keys(response.data.data).forEach((key) => {
                        errors.push(response.data.data[key][0]);
                    });
                    setError(errors);
                    toast.error('Something went wrong..', { autoClose: 3000 })
                }
            }
        }, (error) => {
            toast.error('Something went wrong..', { autoClose: 3000 })
            setLoading(false);
        });
    }

    const isInvalid = (value) => {
        return (!value || value === '' || value === null);
    };

    const onEdit = () => {
        axios.post(API_URL.ADMIN_PRODUCT_GET + id).then((response) => {
            if (response.data.status === 1) {
                getCategoryDrpData();
                getSubCategoryDrpData(response.data.data.category_id);
                getspecsDrp(response.data.data.category_id, response.data.data.specs);
                getseriesDrpData(response.data.data.category_id, response.data.data.sub_category_id);
                getsizeDrpData(response.data.data.category_id, response.data.data.sub_category_id);
                let app_ids = [];
                if (response.data.data.app_ids) {
                    let array = response.data.data.app_ids.split(",");
                    if (array && array.length > 0) {
                        array.map((res) => {
                            app_ids.push({ label: res, value: res });
                        });
                    }
                }
                setFormData({
                    name: response.data.data.name,
                    category_id: response.data.data.category_id,
                    description: response.data.data.description ? RichTextEditor.createValueFromString(response.data.data.description, 'html') : "",
                    sub_category_id: response.data.data.sub_category_id,
                    hsn_code: response.data.data.hsn_code ? response.data.data.hsn_code : "",
                    size_id: response.data.data.size_id,
                    series_id: response.data.data.series_id,
                    unit: response.data.data.unit ? response.data.data.unit : "",
                    weight: response.data.data.weight ? response.data.data.weight : "",
                    accesories: response.data.data.accesories ? response.data.data.accesories : "",
                    image_url: (response.data.data.images.length) ? response.data.data.images[0].image : "",
                    manufacturer: response.data.data.manufacturer ? response.data.data.manufacturer : "",
                    app_ids: app_ids
                })
            }
        });
    }

    const updateObjectInArray = (i, v) => {
        setFormSpecData(current =>
            current.map((obj, k) => {
                if (k === i) {
                    return { ...obj, specs_value: v };
                }
                return obj;
            }),
        );
    };

    const getLoginId = () => {
        let lid = sessionStorage.getItem('login_id');
        setloginId(lid)
    }

    useEffect(() => {
        getLoginId();
    }, [])

    const options = [
        { value: 'Bedroom', label: 'Bedroom' },
        { value: 'Living Room', label: 'Living Room' },
        { value: 'kitchen', label: 'Kitchen' },
        { value: 'Bathroom', label: 'Bathroom' },
        { value: 'Outdoor', label: 'Outdoor' },
    ];

    const onChangeDesc = (v) => {
        console.log(v)
        setFormData({ ...formData, description: v })
    }

    const updateSizeValue = (size_id) => {
        if (loginId == 6) {
            let sid = sizeDrp.find(row => row.id = size_id)
            let name = sid.name.toLowerCase()
            name = sid.name.split(" ")
            name = name[0].split("x")
            if (name[0] == name[1]) {
                setCropType("round")
            } else (
                setCropType("rect")
            )
        }
        
    }

    return (
        <div>
            <div className='card'>
                <div className='card-body'>
                    <h4 className='card-title'>{id ? 'Edit' : 'Add'} Product</h4>
                    {error.length > 0 &&
                        <>
                            {[
                                'danger',
                            ].map((variant) => (
                                <Alert key={variant} variant={variant}>
                                    {error.map((element, key) => {
                                        return (
                                            <div key={key}>{element}</div>
                                        )
                                    })}
                                </Alert>
                            ))}
                        </>
                    }
                    <form className="forms-sample" onSubmit={onSubmit}>
                        <div className='row'>
                            <div className='col-md-3'>
                                <div className='row'>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label>Image</label>
                                            <Crop imagePreview={imagePreview} cropShape={'round'} guid={''}
                                                onChangeCropper={async ({ fileCropped, fileCroppedBase64 }) => {
                                                    setFormData({ ...formData, image: fileCroppedBase64 })
                                                    setImagePreview(fileCroppedBase64);
                                                    //   await uploadFile(fileCropped)
                                                }} />
                                        </div>
                                    </div>
                                    {id &&
                                        <div className='col-md-12'>
                                            <label>Preview</label>
                                            <div>
                                                <a href={formData.image_url} target="_blank" rel="noopener noreferrer">
                                                    <img src={formData.image_url} height={100} width={100} alt="product_image" />
                                                </a>
                                            </div>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className='col-md-9'>
                                <div className='row'>
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Name</label>
                                            <input
                                                type="text"
                                                name='name'
                                                value={formData.name}
                                                className="form-control"
                                                id="name"
                                                onChange={(event) => {
                                                    setFormData({ ...formData, name: event.target.value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    {/* <div className='col-md-4'>
                                        <div className='form-group'>
                                            <label>Design No.</label>
                                            <input
                                                type="text"
                                                name='hsn_code'
                                                value={formData.hsn_code}
                                                className="form-control"
                                                id="hsn_code"
                                                onChange={(event) => {
                                                    setFormData({ ...formData, hsn_code: event.target.value })
                                                }}
                                            />
                                        </div>
                                    </div> */}
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Category</label>
                                            <Form.Select aria-label="Category" value={formData.category_id} onChange={(event) => {
                                                getspecsDrp(event.target.value, []);
                                                getSubCategoryDrpData(event.target.value);
                                                getseriesDrpData(event.target.value);
                                                getsizeDrpData(event.target.value);
                                                setFormData({ ...formData, category_id: event.target.value })
                                            }}>
                                                value={formData.category_id}
                                                <option value="">Select</option>
                                                {
                                                    categoryDrp.map((res, key) => {
                                                        return (
                                                            <option key={key} value={res.id}>{res.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </div>
                                    </div>

                                    <div className='col-md-6'>
                                        <div className="form-group">
                                            <label htmlFor="Sub Category">Sub Category</label>
                                            <span className='start'>*</span>
                                            <Form.Select
                                                aria-label="Sub Category"
                                                value={formData.sub_category_id}
                                                disabled={!formData.category_id}
                                                onChange={(event) => {
                                                    getseriesDrpData(formData.category_id, event.target.value);
                                                    getsizeDrpData(formData.category_id, event.target.value);
                                                    setFormData({ ...formData, sub_category_id: event.target.value })
                                                }}>
                                                value={formData.sub_category_id}
                                                <option value="">Select</option>
                                                {
                                                    subCategoryDrp.map((res, key) => {
                                                        return (
                                                            <option key={key} value={res.id}>{res.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </div>
                                    </div>
                                    
                                    <div className='col-md-6'>
                                        <div className='form-group'>
                                            <label>Size</label>
                                            <Form.Select
                                                aria-label="Series"
                                                value={formData.size_id}
                                                disabled={!formData.category_id}
                                                onChange={(event) => {
                                                    updateSizeValue(event.target.value);
                                                    setFormData({ ...formData, size_id: event.target.value })
                                                }}>
                                                value={formData.size_id}
                                                <option value="">Select</option>
                                                {
                                                    sizeDrp.map((res, key) => {
                                                        return (
                                                            <option key={key} value={res.id}>{res.name}</option>
                                                        )
                                                    })
                                                }
                                            </Form.Select>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className='form-group'>
                                            <label>Suitable For</label>
                                            <Form.Control
                                                as="textarea"
                                                value={formData.accesories}
                                                style={{ height: '100px' }}
                                                onChange={(event) => {
                                                    setFormData({ ...formData, accesories: event.target.value })
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className={id ? 'col-md-12' : 'col-md-12'}>
                                        <div className='form-group'>
                                            <label>Descirption</label>
                                            <RichTextEditor
                                                className='text-editor'
                                                value={formData.description}
                                                onChange={onChangeDesc}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            
                            <div className='text-end'>
                                {
                                    isLoading && <LoaderComponent type={'watch'} height={35} width={35}></LoaderComponent>
                                }
                                <button type="submit" className="btn btn-primary">{id ? 'Update' : 'Create'}</button>
                                <NavLink to="/admin/product" className="btn btn-secondary">
                                    Cancel
                                </NavLink>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );

}
export default ProductFormComponent;